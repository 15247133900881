import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import { useIntl } from "gatsby-plugin-react-intl"
import { motion } from "framer-motion"

const DesignersSection = () => {
    const intl = useIntl()

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }
    const fadeIn = {
        initial: {
            opacity: 0,
            y: 10,
        },
        animate: {
            opacity: 1,
            y: 0,
        },
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulAboutUs {
                nodes {
                    node_locale
                    introduction {
                        childMarkdownRemark {
                            html
                        }
                    }
                    profileImage {
                        gatsbyImageData(width: 200)
                    }
                    camotes
                }
            }
        }
    `)

    const content = intl.locale === "ja" ? data.allContentfulAboutUs.nodes[0] : data.allContentfulAboutUs.nodes[1]

    return (
        <div css={wrapper}>
            <motion.div
                css={innerWrapper}
                initial="initial"
                animate="animate"
                transition={transition}
                variants={fadeIn}
            >
                <GatsbyImage
                    image={getImage(content.profileImage)}
                    key={`designers-image`}
                    alt={`designers image`}
                    objectFit="contain"
                    objectPosition="top center"
                    css={designersImage}
                />
                <div css={textBox}>
                    <h6>{content.camotes}</h6>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: content.introduction.childMarkdownRemark.html.replace(/\n/g, "<br>"),
                        }}
                        css={introduction}
                    />
                </div>
            </motion.div>
        </div>
    )
}
export default DesignersSection

const wrapper = css`
    width: 70vw;
    margin: 0 auto;
    padding: 15vh 0 20vh;

    @media ${device.tb} {
        width: 80vw;
        padding: 5vh 0 7vh;
    }
    @media ${device.sp} {
        padding: 5vh 0;
    }
`
const innerWrapper = css`
    width: 100%;
    display: flex;
    justify-content: space-around;
    @media ${device.sp} {
        display: block;
        width: 100%;
        padding: 5vmax 0 8vmax;
    }
`
const designersImage = css`
    width: 25%;
    height: 20vh;
    @media ${device.sp} {
        width: 60%;
        height: auto;
        display: block;
        margin: 0 auto 6vmax;
    }
`
const textBox = css`
    width: 65%;
    h6 {
        margin-bottom: 1.5rem;
    }

    @media ${device.sp} {
        width: 100%;
    }
`
const introduction = css`
    a {
        border-bottom: solid 1px black;
    }
`
