import * as React from "react"
import { Layout } from "../components/layout"
import DesignersSection from "../components/designersSection"
import NewsArticle from "../components/newsArticle"
import InstagramPost from "../components/instagramPost"
import ContactUsSection from "../components/contactUsSection"

const DesignersPage = () => {
    return (
        <Layout>
            <DesignersSection />
            <NewsArticle />
            <InstagramPost />
            <ContactUsSection />
        </Layout>
    )
}
export default DesignersPage
