import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const NewsArticle = () => {
    const intl = useIntl()

    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1, ease: [0.6, -0.05, 0.01, 0.9], delay: 0.25 }
    const fadeIn = {
        initial: {
            opacity: 0,
            y: 15,
        },
        animate: {
            opacity: 1,
            y: 0,
        },
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulNewsContents(limit: 2, sort: { order: ASC, fields: newsDate }) {
                nodes {
                    node_locale
                    newsDate
                    newsTitle
                    newsDescription {
                        childMarkdownRemark {
                            html
                        }
                    }
                    newsThumbnail {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulNewsContents.nodes[0] : data.allContentfulNewsContents.nodes[1]

    return (
        <motion.div
            css={wrapper}
            ref={ref}
            initial="initial"
            animate={controls}
            transition={transition}
            variants={fadeIn}
        >
            <div css={textBox}>
                <p css={dateText}>{content.newsDate}</p>
                <h6>{content.newsTitle}</h6>
                <div
                    dangerouslySetInnerHTML={{
                        __html: content.newsDescription.childMarkdownRemark.html.replace(/\n/g, "<br>"),
                    }}
                    css={introduction}
                />
            </div>
            <GatsbyImage
                image={getImage(content.newsThumbnail)}
                key={`news-article-image`}
                alt={`news article image`}
                css={newsArticleImage}
                objectFit="contain"
                objectPosition="top center"
                layout="fullWidth"
            />
        </motion.div>
    )
}
export default NewsArticle

const wrapper = css`
    width: 70vw;
    margin: 0 auto 25vh;
    padding: 6% 5%;
    display: flex;
    justify-content: space-between;
    background: #f8f8f8;
    @media ${device.tb} {
        margin: 0 auto 7vmax;
    }
    @media ${device.sp} {
        display: block;
        width: 80vmin;
        margin: 0 auto 15vmax;
        padding: 8% 5%;
    }
`
const newsArticleImage = css`
    width: 35%;
    height: auto;
    @media ${device.sp} {
        width: 100%;
    }
`
const textBox = css`
    width: 60%;
    h6 {
        margin-bottom: 2.6rem;
    }

    @media ${device.sp} {
        width: 100%;
        margin-bottom: 6vmax;
    }
`
const introduction = css`
    a {
        border-bottom: solid 1px black;
    }
`
const dateText = css`
    font-size: 1.2rem;
`
