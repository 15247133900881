import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { device } from "../styles/device"

const InstagramPost = () => {
    const data = useStaticQuery(graphql`
        query {
            allInstagramContent {
                nodes {
                    caption
                    permalink
                    username
                    id
                    localImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)
    const content = data.allInstagramContent.nodes

    return (
        <div css={wrapper}>
            <h6 css={headline}>INSTAGRAM</h6>
            <a href="https://www.instagram.com/hadawa_official/" target="_blank" rel="noreferrer" css={usernameStyle}>
                {`@${content[0].username}`}
            </a>
            <div css={instaContainer}>
                {content.slice(0, 8).map((post, index) => (
                    <div css={article} key={post.id}>
                        <a href={post.permalink} target="_blank" rel="noreferrer">
                            <GatsbyImage
                                image={getImage(post.localImage.childImageSharp)}
                                key={post.permalink}
                                alt={`instagram post ${index}`}
                                css={instaPostImage}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default InstagramPost

const wrapper = css`
    text-align: center;
    width: 80%;
    margin: 0 auto 20vh;

    @media ${device.sp} {
        width: 90%;
    }
`
const instaContainer = css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;

    @media ${device.sp} {
        grid-template-columns: repeat(2, 1fr);
    }
`
const headline = css`
    margin-bottom: 1rem;
`
const usernameStyle = css`
    margin-bottom: 3rem;
    display: inline-block;
`
const article = css`
    box-shadow: 0px 0px 3px #e8e8e8;
    height: calc(20vw - 9px);

    a {
        position: relative;
        width: 100%;
        height: 100%;
    }

    @media ${device.sp} {
        height: calc(45vw - 6px);
    }
`
const instaPostImage = css`
    width: 100%;
    height: 100%;
    transition: 0.5s cubic-bezier(0.6, -0.05, 0.01, 0.9);

    &:hover {
        opacity: 0.3;
    }
`
